<!-- @format -->

<template>
	<div>
		<div style="background-color: rgb(76, 92, 122); margin-top: 40px">
			<div class="table-header container">
				<div>All stock items</div>
				<div>
					<b-button
						class="margin-right-10"
						type="is-danger"
						outlined
						@click="toggle"
						icon-left="refresh"
						style="width: 150px"
					>
						Show / Hide
					</b-button>
				</div>
			</div>
		</div>
		<div v-if="loadingStockItems" class="loading-tag">Loading...</div>
		<div v-if="toggleTableConsumption">
			<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
				<ve-table
					:rows="listArr"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:perPage="perPageProp"
					maxHeight="350px"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
// ? Composables -----------------------------------------------------------------------------------------------------------
import useColumnsState from '@/_srcv2/pages/admin/create-document-add-lines/_composables/useColumnsState.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
import useDraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/useDraftDocumentLines.js'
// ? GraphQL -----------------------------------------------------------------------------------------------------------------------------
import GetStockItemsQuery from '@/_srcv2/pages/admin/create-document-add-lines/get-stock-items/GetStockItemsQuery.graphql'

export default {
	name: 'GetStockItems',
	setup() {
		const { isFormDisabled } = useDraftDocumentLines()
		const perPageProp = ref(10)
		const { customerIdCR: customerId } = useCheckRisk()
		const labelPosition = ref('on-border')
		// todo Time Interval Dispatches
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchStockItems, loading: loadingStockItems } = useQuery(
			GetStockItemsQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// todo Create Table
		const tableValues = ref([])
		const getData = () => {
			tableValues.value = []
			refetchStockItems().then((result) => {
				tableValues.value = result.data.stock.map((item) => {
					return {
						stockId: item.stock_id,
						itemName: item.stock_name,
						amount:
							item.outstanding_balance + item.debit_amount - item.credit_amount,
						stockUnit: item.stock_unit,
						stockNormalPrice: item.stock_price,
						stockItemVatPercent: item.stock_moms,
					}
				})
			})
		}
		const listArr = computed(() => tableValues.value)
		// -------------------------------------------------------------------------
		const { documentModalState } = useDocumentModalState()
		const setDocumentModalState = (item) => {
			console.log('selected stock item', item)
			documentModalState.ourCompany = Store.getters.getUserCurrentCompany
			documentModalState.customerId = customerId.value
			documentModalState.stockId = item.stockId
			documentModalState.lineInfo = `${item.stockId} - ${item.itemName} - ${item.stockUnit}`
			documentModalState.normalPrice = item.stockNormalPrice
			documentModalState.vatPercent = item.stockItemVatPercent
			documentModalState.isModalVisible = true
			documentModalState.isAddOrEdit = 'add'
			documentModalState.keyModal += 1
			console.log('documentModalState', documentModalState)
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				const item = params.row
				setDocumentModalState(item)
			}
		}
		const onRowDoubleClick = (params) => {
			const item = params.row
			setDocumentModalState(item)
		}
		// -------------------------------------------------------------------------
		const { columnsList } = useColumnsState()
		// -------------------------------------------------------------------------
		onMounted(() => {
			tableValues.value = []
			getData()
		})
		const toggleTableConsumption = ref(true)
		const toggle = () => {
			toggleTableConsumption.value = !toggleTableConsumption.value
		}
		return {
			perPageProp,
			labelPosition,
			getData,
			listArr,
			columnsList,
			loadingStockItems,
			onCellClick,
			onRowDoubleClick,
			toggleTableConsumption,
			toggle,
			isFormDisabled,
		}
	},
}
</script>

<style scoped>
.loading-tag {
	font-size: 2em;
	font-weight: bolder;
	color: #9b1c1c;
}
.table-header {
	color: white;
	padding: 20px;
	font-size: x-large;
	font-weight: 500;
	margin-bottom: -10px;
}
.container {
	display: flex;
	justify-content: space-between;
}
</style>
