<!-- @format -->

<template>
	<div>
		<div style="background-color: rgb(76, 92, 122); margin-top: 40px">
			<div class="table-header container">
				<div>Products that consumed by the customer</div>
				<div>
					<b-button
						class="margin-right-10"
						type="is-danger"
						outlined
						@click="toggle"
						icon-left="refresh"
						style="width: 150px"
					>
						Show / Hide
					</b-button>
				</div>
			</div>
		</div>

		<div v-if="loadingDispatch" class="loading-tag">Loading...</div>
		<div v-if="toggleTableConsumption">
			<div :class="isFormDisabled ? 'disable-div' : 'enable-div'">
				<ve-table
					:rows="listArr"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:perPage="perPageProp"
					maxHeight="350px"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import moment from 'moment'
import { useQuery } from '@vue/apollo-composable'
// ? Composables -----------------------------------------------------------------------------------------------------------
import useColumnsState from '@/_srcv2/pages/admin/create-document-add-lines/_composables/useColumnsState.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
import useDraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/useDraftDocumentLines.js'
// ? GraphQL -----------------------------------------------------------------------------------------------------------------------------
import GetCustomerConsumptionsQuery from '@/_srcv2/pages/admin/create-document-add-lines/get-customer-consumptions/GetCustomerConsumptionsQuery.graphql'
import Store from '@/store'
export default {
	name: 'GetCustomerConsumptions',
	setup() {
		const { isFormDisabled } = useDraftDocumentLines()
		const perPageProp = ref(25)
		const { customerIdCR: customerId } = useCheckRisk()
		const labelPosition = ref('on-border')
		let date = new Date()
		const parameters = reactive({
			beginningDate: moment(date).subtract(90, 'days').format('YYYY-MM-DD'),
			endingDate: moment(date).format('YYYY-MM-DD'),
		})
		// todo ------------------------------------------------------------
		// ? Time Interval Dispatches
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchDispatch, loading: loadingDispatch } = useQuery(
			GetCustomerConsumptionsQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				customer_id: customerId.value,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const { floatedCurrencyAddedNumber } = useReportNumber()
		// todo ---------- Create Table -----------------------------------
		const tableValues = ref([])
		const getData = () => {
			tableValues.value = []
			refetchDispatch().then((result) => {
				console.log('**** refetch result', result)
				tableValues.value = result.data.goods_transactions
					.map((item) => {
						return {
							stockId: item.goods_transactions_stock_rel.stock_id,
							itemName: item.goods_transactions_stock_rel.stock_name,
							amount:
								item.goods_transaction_goods_transaction_rel_aggregate.aggregate
									.sum.amount_credit,
							consumptionWorth: floatedCurrencyAddedNumber(
								item.goods_transaction_goods_transaction_rel_aggregate.aggregate
									.sum.line_price_total_credit,
							),
							stockUnit: item.goods_transactions_stock_rel.stock_unit,
							stockNormalPrice: item.goods_transactions_stock_rel.stock_price,
							stockItemVatPercent: item.goods_transactions_stock_rel.stock_moms,
						}
					})
					.filter((item) => item.amount !== 0)
			})
		}
		const listArr = computed(() => tableValues.value || [])
		// todo -------------------------------------------------------------------------
		const { documentModalState } = useDocumentModalState()
		const setDocumentModalState = (item) => {
			console.log('selected stock item', item)
			documentModalState.ourCompany = Store.getters.getUserCurrentCompany
			documentModalState.customerId = customerId.value
			documentModalState.stockId = item.stockId
			documentModalState.lineInfo = `${item.stockId} - ${item.itemName} - ${item.stockUnit}`
			documentModalState.normalPrice = item.stockNormalPrice
			documentModalState.vatPercent = item.stockItemVatPercent
			documentModalState.isModalVisible = true
			documentModalState.isAddOrEdit = 'add'
			documentModalState.keyAddDocumentLinesModal += 1
			console.log('documentModalState', documentModalState)
		}
		// todo -------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				const item = params.row
				setDocumentModalState(item)
			}
		}
		const onRowDoubleClick = (params) => {
			const item = params.row
			setDocumentModalState(item)
		}
		// todo -------------------------------------------------------------------------
		const { columnsList } = useColumnsState()
		// todo -------------------------------------------------------------------------
		onMounted(() => {
			console.log('onMounted is fired')
			console.log('parameters', parameters)
			console.log('tableValues.value', tableValues.value)
			tableValues.value = []
			getData()
		})
		// todo --------------------------------------------------------------------------
		const toggleTableConsumption = ref(true)
		const toggle = () => {
			toggleTableConsumption.value = !toggleTableConsumption.value
		}
		// --------------------------------------------------------------------------
		return {
			perPageProp,
			labelPosition,
			getData,
			listArr,
			columnsList,
			loadingDispatch,
			onCellClick,
			onRowDoubleClick,
			parameters,
			toggleTableConsumption,
			toggle,
			isFormDisabled,
		}
	},
}
</script>

<style scoped>
.margin-top-20 {
	margin-top: 20px;
}
.loading-tag {
	font-size: 2em;
	font-weight: bolder;
	color: #9b1c1c;
}
.table-header {
	color: white;
	padding: 20px;
	font-size: x-large;
	font-weight: 500;
	margin-bottom: -10px;
}
.container {
	display: flex;
	justify-content: space-between;
}
</style>
