<!-- @format -->

<template>
	<div>
		<div id="addDocumentLines">
			<div class="margin-top-30">
				<SummaryDocumentHeaders />
			</div>
			<div class="margin-top-30">
				<DraftDocumentLines />
			</div>
			<div>
				<SaveTheDocument />
			</div>
			<div v-if="createLevel !== 4">
				<GetCustomerConsumptions />
			</div>
			<div v-if="createLevel !== 4">
				<GetStockItems />
			</div>
			<div v-if="createLevel === 4">
				<EnterInvoiceNumber />
			</div>
		</div>
		<div class="max-width-150">
			<AddDocumentLinesModalFrame
				v-if="isModalVisible"
				@close="closeModal"
				:key="keyModal"
			/>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'
// ? Components -------------------------------------------------------------------------------------------------------------------------------------------
import SummaryDocumentHeaders from '@/_srcv2/pages/admin/create-document-add-lines/_summary-document-headers/SummaryDocumentHeaders.vue'
import DraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/DraftDocumentLines.vue'
import SaveTheDocument from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/SaveTheDocument.vue'
import GetCustomerConsumptions from '@/_srcv2/pages/admin/create-document-add-lines/get-customer-consumptions/GetCustomerConsumptions.vue'
import GetStockItems from '@/_srcv2/pages/admin/create-document-add-lines/get-stock-items/GetStockItems.vue'
import AddDocumentLinesModalFrame from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/AddDocumentLinesModalFrame.vue'
import EnterInvoiceNumber from '@/_srcv2/pages/sale-invoice/credit-invoice/components/EnterInvoiceNumber.vue'
// ? Composables ------------------------------------------------------------------------------------------------------------------------------------------
import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'

export default {
	name: 'AddDocumentLines',
	components: {
		SummaryDocumentHeaders,
		DraftDocumentLines,
		GetStockItems,
		GetCustomerConsumptions,
		SaveTheDocument,
		AddDocumentLinesModalFrame,
		EnterInvoiceNumber,
	},
	setup() {
		const { showAddGoodsTransactions, createLevel } =
			useCreateDocumentComponentState()
		const { isModalVisible, keyModal } = useDocumentModalState()
		const closeModal = () => (isModalVisible.value = false)
		const show = ref(false)
		return {
			showAddGoodsTransactions,
			isModalVisible,
			keyModal,
			closeModal,
			show,
			createLevel,
		}
	},
}
</script>

<style lang="scss" scoped></style>
