/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'

import useDocumentModalState from '@/_srcv2/pages/admin/create-document-add-lines/add-document-lines-modal/useDocumentModalState.js'
Vue.use(VueCompositionApi)

const { isAddDisabled } = useDocumentModalState()

const modelOrderLines = reactive({
	transactionId: '',
	transactionType: 'customer order',
	stockId: '',
	lineInfo: '',
	normalPrice: '',
	campaignPrice: '',
	customerSpecialPrice: '',
	customerDiscountedPrice: '',
	customerInvoicePrice: '',
	customerInvoiceExchangePrice: '',
	vatPercent: '',
	amountCredit: '',
	// -----------------------------------------------
	// goodsTransactionsInvoicePrice: 0,
	// goodsTransactionsInvoicePriceExchange: 0,
})
const schemaOrderLines = reactive({
	groups: [
		{
			legend: 'Stock Item to add to Order',
			id: 'orderLines',
			featured: false,
			fields: [
				{
					type: 'input',
					inputType: 'text',
					label:
						'Order info (stock id - item name - unit) You can edit if you need',
					model: 'lineInfo',
					placeholder: 'Please enter Information',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (errors) {
						if (errors.length > 0) {
							console.warn(
								'Validation error in Information field! Errors:',
								errors,
							)
						}
					},
				},
				// -----------------------------------------------------------------------------
				{
					type: 'cleave',
					label: `Item List Price`,
					model: 'normalPrice',
					placeholder: 'Please get stock item normal price',
					featured: true,
					disabled: true,
					readonly: true,
					required: true,
					validator: [],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in Price field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: `Item Campaign Price`,
					model: 'campaignPrice',
					placeholder: 'Please get stock item campaign price',
					featured: true,
					disabled: true,
					readonly: true,
					required: true,
					validator: [],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in Price field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: `Item Customer Special Price`,
					model: 'customerSpecialPrice',
					placeholder: 'Please get stock item normal price',
					featured: true,
					disabled: true,
					readonly: true,
					required: true,
					validator: [],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},

					onValidated: function (errors) {
						if (errors.length > 0)
							console.warn('Validation error in Price field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label:
						'Item Discounted Price (depends on customer class: a,b,c,d,s,hra,hrb,hrc,hrd,z)',
					model: 'customerDiscountedPrice',
					placeholder: 'Please get stock item discounted price',
					featured: true,
					disabled: true,
					readonly: true,
					required: true,
					validator: [],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (errors) {
						if (errors.length > 0)
							console.warn('Validation error in Price field! Errors:', errors)
					},
				},
				{
					type: 'cleave',
					label: `!!! Pay Attention Item Invoice Price in kr.`,
					model: 'customerInvoicePrice',
					placeholder: 'Please get stock item sale-invoice price',
					featured: true,
					disabled: true,
					readonly: true,
					required: true,
					validator: ['notnegativenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						console.log('onValidated', model)
						if (errors.length > 0) {
							console.warn('Validation error in Price field! Errors:', errors)
						}
					},
				},
				{
					type: 'cleave',
					label: 'Item Invoice Exchange Price',
					model: 'customerInvoiceExchangePrice',
					placeholder: 'Please get customer sale-invoice exchange price',
					featured: true,
					disabled: true,
					readonly: false,
					required: true,
					validator: ['notnegativenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							isAddDisabled.value = true
							console.warn('Validation error in Price field! Errors:', errors)
						}
					},
				},
				//------------------------------------------------------------------------------
				{
					type: 'input',
					inputType: 'number',
					label: `Order Amount`,
					model: 'amountCredit',
					placeholder: 'Please enter Order Amount',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['required', 'integer', 'notzero', 'notnull', 'notempty'],
					onValidated: function (model, errors) {
						console.log('*** validated', model)
						if (errors.length < 1) {
							isAddDisabled.value = true
							console.warn(
								'Validation error in Information field! Errors:',
								errors,
							)
						}
					},
				},
			],
		},
	],
})
const formOptionsOrderLines = reactive({
	validateAfterLoad: false,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'orderLines',
})
export default function useDocumentModalSchemaOptions() {
	return {
		modelOrderLines,
		schemaOrderLines,
		formOptionsOrderLines,
		...toRefs(modelOrderLines),
	}
}
