<!-- @format -->

<template>
	<div>
		<div id="create-order-frame" v-if="createLevel === 1">
			<div style="background-color: rgb(76, 92, 122); padding-bottom: 30px">
				<!-- <form style="padding-top: 20px; padding-left: 15px">
					<label for="dNum" style="color: white">Existing Dispatch Number</label
					><br />
					<input
						type="text"
						id="dNum"
						name="dNum"
						v-model="existingDispatchNumber"
						maxlength="9"
						style="
							margin-top: 10px;
							width: 180px;
							height: 30px;
							font-size: large;
							font-weight: 500;
						"
					/><br />
				</form> -->
				<b-button
					v-if="showSaveAllButton"
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="onSave('save')"
					:disabled="disableButtons"
				>
					Save Order
				</b-button>
				<b-button
					v-if="showSaveAllButton"
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(61, 196, 134); color: white"
					@click="onSave('lock')"
					:disabled="disableButtons"
				>
					Ready to Dispatch
				</b-button>
				<b-button
					v-if="showSaveAllButton"
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(52, 136, 206); color: white"
					@click="onSave('view')"
					:disabled="disableButtons"
				>
					Save Order & View
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					type="is-danger"
					outlined
					@click="cancelTheOrder"
				>
					{{ cancelOrderText }}
				</b-button>
				<!-- <b-button
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(52, 136, 206); color: white"
					@click="addToExistingDispatch"
					:disabled="existingDispatchNumber.length < 9"
				>
					AddToExistingDispatch
				</b-button> -->
			</div>
			<div v-if="isModalVisible">
				<ModalOrderFrame :onClose="onClose" />
			</div>
			<div v-if="isModalDispatchVisible">
				<ModalDispatchFrame :onClose="onClose" processType="dispatch" />
			</div>
		</div>
		<!-- todo ************************************************************************************ -->
		<div v-if="createLevel > 2">
			<div
				v-if="applyInvoiceFee.showCheckbox"
				style="
					height: 85px;
					margin-top: -30px;
					margin-bottom: 0px;
					color: white;
					background-color: rgb(78, 92, 123);
				"
			>
				<div
					style="
						margin-left: 20px;
						margin-top: 30px;
						padding-top: 25px;
						font-weight: 900;
					"
				>
					{{ applyInvoiceFee.text }} &nbsp; - &nbsp; {{ feeMessage }}
				</div>
				<div class="margin-left-20 margin-top-15">
					<div class="block">
						<b-radio
							:disabled="disableRadioButton"
							v-model="applyInvoiceFee.bool"
							type="is-success"
							name="name"
							:native-value="boolTrue"
						>
							Apply
						</b-radio>
						<b-radio
							:disabled="disableRadioButton"
							v-model="applyInvoiceFee.bool"
							type="is-danger"
							name="name"
							:native-value="boolFalse"
						>
							DO NOT APPLY
						</b-radio>
					</div>
				</div>
			</div>
		</div>

		<!-- todo ************************************************************************************ -->
		<div id="create-order-dispatch-invoice-frame" v-if="createLevel > 1">
			<div style="background-color: rgb(76, 92, 122); padding-bottom: 30px">
				<b-button
					v-if="showSaveAllButton"
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="onSaveAndLock"
					:disabled="disableButtons"
				>
					Save & Lock All
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(61, 196, 134); color: white"
					@click="onGetOrder"
					:disabled="disableGetButtons"
				>
					Get the order
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(52, 136, 206); color: white"
					@click="onGetDispatch"
					:disabled="disableGetButtons"
				>
					Get the dispatch
				</b-button>
				<b-button
					v-if="createLevel === 3"
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(109, 65, 161); color: white"
					@click="onGetInvoice"
					:disabled="disableGetButtons"
				>
					Get the invoice
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					type="is-danger"
					outlined
					@click="onCancelAll"
				>
					{{ cancelCloseText }}
				</b-button>
				<div style="margin-left: 15px; margin-top: 20px; color: white">
					{{ documentNumbers }}
				</div>
				<div v-if="isModalOrderVisible">
					<ModalOrderFrame :onClose="onClose" />
				</div>
				<div v-if="isModalDispatchVisible">
					<ModalDispatchFrame :onClose="onClose" processType="dispatch" />
				</div>
				<div v-if="isModalInvoiceVisible">
					<ModalInvoiceFrame :onClose="onClose" processType="sale-invoice" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	computed,
	ref,
	reactive,
	watch,
	watchEffect,
	onMounted,
	onUnmounted,
} from '@vue/composition-api'
import { useMutation, useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import addDays from 'add-days'
import dateformat from 'dateformat'
// ? Composables -----------------------------------------------------------------------------------------------------------------
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'
import useOrderHeadersModelSchemaOptions from '@/_srcv2/pages/admin/_shared/useOrderHeadersModelSchemaOptions.js'
import useCheckRisk from '@/_srcv2/pages/_utilities/_check-risk/check-customer-risk/useCheckRisk.js'
import useDocumentHeaderState from '@/_srcv2/pages/admin/_shared/useDocumentHeaderState.js'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import useDraftDocumentLines from '@/_srcv2/pages/admin/create-document-add-lines/draft-document-lines/useDraftDocumentLines.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid.js'
// ? GraphQL -----------------------------------------------------------------------------------------------------------------
import GetOurCompanyFeeStatusQuery from '@/_srcv2/pages/sale-invoice/graphql/GetOurCompanyFeeStatusQuery.graphql'
import CreateOrderNumberMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderNumberMutation.graphql'
import CreateOrderDispatchNumbersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderDispatchNumbers.graphql'
import CreateOrderDispatchInvoiceNumbersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderDispatchInvoiceNumbers.graphql'
import CreateOrderHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateOrderHeadersMutation.graphql'
import CreateDispatchHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateDispatchHeadersMutation.graphql'
import CreateInvoiceHeadersMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/CreateInvoiceHeadersMutation.graphql'
import TriggerStockCreditAmountsMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/TriggerStockCreditAmountsMutation.graphql'
// ? Components -----------------------------------------------------------------------------------------------------------------
import ModalOrderFrame from '@/_srcv2/pages/_reports/get-data-and-report/order/ModalOrderFrame.vue'
import ModalDispatchFrame from '@/_srcv2/pages/_reports/get-data-and-report/dispatch/ModalDispatchFrame.vue'
import ModalInvoiceFrame from '@/_srcv2/pages/_reports/get-data-and-report/invoice/ModalInvoiceFrame.vue'
// *****************************************************************************************************************************
import AddToExistingDispatchMutation from '@/_srcv2/pages/admin/create-document-add-lines/save-the-documents/graphql/AddToExistingDispatchMutation.graphql'
import numeral from 'numeral'

export default {
	name: 'SaveTheDocument',
	components: {
		ModalOrderFrame,
		ModalDispatchFrame,
		ModalInvoiceFrame,
	},
	setup() {
		// ***********************************************************************************************************************

		// todo --------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult } = useQuery(
			GetOurCompanyFeeStatusQuery,
			() => ({
				company_nickname: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// todo ----------------------------------------------------------------------
		const invoiceFee = reactive({
			isAppliable: false,
			invoiceFeeAmount: 0,
			invoiceFeeVat: 0,
		})
		onResult((result) => {
			const obj = result.data.companies_by_pk
			console.log('==========>>>> obj', obj)
			invoiceFee.isAppliable = obj.is_invoice_fee_appliable
			invoiceFee.invoiceFeeAmount = obj.invoice_fee_to_apply
			invoiceFee.invoiceFeeVat = obj.invoice_fee_vat
			console.log('invoiceFee', invoiceFee)
			console.log('isInvoiceSendByEmail', isInvoiceSendByEmail.value)
		})
		// todo ----------------------------------------------------------------------
		const boolTrue = ref(true)
		const boolFalse = ref(false)
		// todo ----------------------------------------------------------------------

		const applyInvoiceFee = reactive({
			bool: true,
			text: 'Faktura Avgift is applied',
			showCheckbox: false,
		})
		const { getUUID } = useGetUuid()
		const disableRadioButton = ref()
		const isFeeAddable = ref()
		const getApplyInvoiceValue = () => {
			console.log('getApplyInvoiceValue is fired')
			if (invoiceFee.isAppliable === false) {
				disableRadioButton.value = true
				applyInvoiceFee.bool = false
				applyInvoiceFee.text = 'Faktura Avgift is NOT APPLIABLE'
				isFeeAddable.value = false
			} else if (orderType.value !== 'Inland') {
				disableRadioButton.value = true
				applyInvoiceFee.bool = false
				applyInvoiceFee.text =
					'Faktura Avgift is NOT APPLIABLE (Invoice to abroad)'
				isFeeAddable.value = false
			} else if (isInvoiceSendByEmail.value === true) {
				applyInvoiceFee.bool = false
				applyInvoiceFee.text = 'Faktura Avgift is NOT applied (Email Invoice)'
				isFeeAddable.value = false
			} else {
				disableRadioButton.value = false
				applyInvoiceFee.bool = true
				applyInvoiceFee.text = 'Faktura Avgift is applied'
				isFeeAddable.value = true
			}
		}
		const feeMessage = computed(() => {
			if (applyInvoiceFee.bool === false) {
				return ''
			} else if (applyInvoiceFee.bool === true) {
				return `(Fee: ${numeral(invoiceFee.invoiceFeeAmount / 100).format(
					'0,0.00',
				)} kr. moms: ${numeral(invoiceFee.invoiceFeeVat / 100).format(
					'0,0.00',
				)} kr.)`
			} else {
				return 'Please select whether the will be applied or not'
			}
		})
		const documentTransactionId = ref('')
		onMounted(() => {
			reportProcessType.value = 'add'
			documentTransactionId.value = getUUID()
			getApplyInvoiceValue()
			applyInvoiceFee.showCheckbox = false
			setTimeout(() => {
				getApplyInvoiceValue()
				applyInvoiceFee.showCheckbox = true
			}, 1000)
		})
		// todo ----------------------------------------------------------------------
		const i = ref(0)
		watch(
			() => applyInvoiceFee.bool,
			(newValue) => {
				i.value += 1
				console.log(i.value, `New Value is: ${newValue}`)
				if (i.value > 2) {
					if (applyInvoiceFee.bool === true) {
						applyInvoiceFee.text = 'Faktura Avgift is applied (User Choice)'
						isFeeAddable.value = true
					} else if (applyInvoiceFee.bool === false) {
						applyInvoiceFee.text = 'Faktura Avgift is NOT applied (User Choice)'
						isFeeAddable.value = false
					} else {
						alert('Will Faktura Avgift be applied or not, please choose')
					}
				}
			},
		)
		// todo ---------------------------------------------------------------------------
		// *****************************************************************************************************************************
		const {
			createLevel,
			showAddGoodsTransactions,
			showSearchActiveCustomers,
			showDocumentHeadersGroup,
			// showGetDocumentHeadersDataComponent,
		} = useCreateDocumentComponentState()
		const { orderLinesArray, isFormDisabled } = useDraftDocumentLines()

		const {
			orderType,
			orderLanguage,
			orderExchangeUnit,
			orderExchangeRate,
			dispatchAddressId,
			invoiceAddressId,
		} = useOrderHeadersModelSchemaOptions()
		const { orderIssueDate, documentCustomerEmail } = useDocumentHeaderState()
		const {
			customerIdCR: customerId,
			customerTitleCR: customerTitle,
			customerNicknameCR: customerNickname,
			customerOrgNumberCR: customerOrgNum,
			term: customerTerm,
			termsCR: terms,
			showCheckRiskModule,
		} = useCheckRisk()
		const isInvoiceSendByEmail = ref()
		isInvoiceSendByEmail.value = terms.value.includes('Email') ? true : false
		// todo ----------------------------------------------------------------------------
		const isOrderLocked = ref(false)
		const ourCompany = Store.getters.getUserCurrentCompany
		const orderNumber = ref('')
		const dispatchNumber = ref('')
		const invoiceNumber = ref('')
		const documentNumbers = computed(() => {
			return `Order Number: ${orderNumber.value} - Dispatch Number: ${dispatchNumber.value} - Invoice Number: ${invoiceNumber.value}`
		})
		const cancelOrderText = ref('Cancel the Order')
		const setCancelOrderText = (text) => {
			cancelOrderText.value = text
		}
		const cancelCloseText = ref('Cancel All')
		const setCancelCloseText = (text) => {
			cancelCloseText.value = text
		}
		const convertToNumeric = (stringExc) => {
			const formattedNumber = parseFloat(stringExc)
			console.log('formattedNumber', formattedNumber)
			console.log('typeof formattedNumber', typeof formattedNumber)
			return formattedNumber
		}
		// todo -------------------------------------------------------------------
		const disableButtons = ref(true)
		const disableGetButtons = ref(true)
		const showSaveAllButton = ref(true)
		// todo -------------------------------------------------------------------
		watchEffect(() => {
			if (orderLinesArray.value !== null || undefined) {
				disableButtons.value = orderLinesArray.value.length <= 0
			} else {
				disableButtons.value = true
			}
		})
		onMounted(() => {
			setCancelCloseText('Cancel All')
			setCancelOrderText('Cancel the Order')
			isFormDisabled.value = false
			showSaveAllButton.value = true
		})
		// ? get new order number from database
		const { mutate: getNewOrderNumber } = useMutation(
			CreateOrderNumberMutation,
			() => ({
				variables: {
					company_nickname: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		// todo ---------------------------------------------------------------------------
		// ? get new order and dispatch number from database
		const { mutate: getNewOrderDispatchNumbers } = useMutation(
			CreateOrderDispatchNumbersMutation,
			() => ({
				variables: {
					company_nickname: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		// todo ---------------------------------------------------------------------------
		// ? get new order, dispatch and invoice number from database
		const { mutate: getNewOrderDispatchInvoiceNumbers } = useMutation(
			CreateOrderDispatchInvoiceNumbersMutation,
			() => ({
				variables: {
					company_nickname: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		// todo ---------------------------------------------------------------------------
		// ? Create new order header ---------------------------------------------
		// * AddNewOrderMutation
		const orderMutationVariable = computed(() => {
			return [
				{
					company_id: ourCompany,
					order_number: orderNumber.value,
					order_date: orderIssueDate.value,
					order_type: orderType.value,
					order_exchange_unit: orderExchangeUnit.value,
					order_exchange_rate: convertToNumeric(orderExchangeRate.value),
					order_language: orderLanguage.value,
					customer_id: customerId.value,
					dispatch_address_id: dispatchAddressId.value,
					invoice_address_id: invoiceAddressId.value,
					order_lock: isOrderLocked.value,
					order_heders_goods_transactions_rel: {
						data: orderLinesArray.value,
					},
				},
			]
		})
		// ? --------------------------------------------------------------------------------
		const { mutate: addNewOrder, onDone: onDoneAddNewOrder } = useMutation(
			CreateOrderHeadersMutation,
			() => ({
				variables: {
					input: orderMutationVariable.value,
				},
			}),
		)
		// ? --------------------------------------------------------------------------------
		onDoneAddNewOrder((result) => console.log('result', result))
		// todo ---------------------------------------------------------------------------
		// ! Create new dispatch header ---------------------------------------------
		// * AddNewDispatchMutation
		const dispatchMutationVariable = computed(() => {
			return [
				{
					company_id: ourCompany,
					customer_id: customerId.value,
					dispatch_address_id: dispatchAddressId.value,
					dispatch_date: orderIssueDate.value,
					dispatch_exchange_rate: convertToNumeric(orderExchangeRate.value),
					dispatch_exchange_unit: orderExchangeUnit.value,
					dispatch_language: orderLanguage.value,
					dispatch_lock: true,
					dispatch_number: dispatchNumber.value,
					dispatch_type: orderType.value,
					invoice_address_id: invoiceAddressId.value,
				},
			]
		})
		// todo ------------------------------------------------------------------------
		const { mutate: addNewDispatch } = useMutation(
			CreateDispatchHeadersMutation,
			() => ({
				variables: {
					input: dispatchMutationVariable.value,
					order_number: orderNumber.value,
					dispatch_number: dispatchNumber.value,
				},
			}),
		)
		// ! ---------------------------------------------------------------------
		// * TriggerStockCreditAmountsMutation
		const mutationVariablesMutateStockCreditAmountMutation = reactive({
			company_id: ourCompany,
			stock_id: '',
			credit_amount: 0,
		})
		const { mutate: mutateStockCreditAmount } = useMutation(
			TriggerStockCreditAmountsMutation,
			() => ({
				variables: mutationVariablesMutateStockCreditAmountMutation,
			}),
		)
		// * ---------------------------------------------------------------------
		const condition = true
		const setCreditAmounts = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					const linesLength = orderLinesArray.value.length
					for (let i = linesLength; i > 0; i--) {
						console.log('line:', orderLinesArray.value[i - 1])
						let item = orderLinesArray.value[i - 1]
						mutationVariablesMutateStockCreditAmountMutation.company_id =
							ourCompany
						mutationVariablesMutateStockCreditAmountMutation.stock_id =
							item.stock_id
						mutationVariablesMutateStockCreditAmountMutation.credit_amount =
							item.dispatch_amount
						mutateStockCreditAmount()
					}
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ----------------------------------------------------------------------------
		// * AddNewInvoiceMutation
		// const { getUUID } = useGetUuid();
		const invoiceMutationVariable = computed(() => {
			return {
				input: [
					{
						document_transaction_id: getUUID(),
						our_company: ourCompany,
						customer_id: customerId.value,
						invoice_number: invoiceNumber.value,
						transaction_date: orderIssueDate.value,
						invoice_date: orderIssueDate.value,
						invoice_due_date: dateformat(
							addDays(Date.parse(orderIssueDate.value), customerTerm.value),
							'yyyy-mm-dd',
						),
						invoice_type: orderType.value,
						invoice_language: orderLanguage.value,
						invoice_exchange_rate: convertToNumeric(orderExchangeRate.value),
						invoice_exchange_unit: orderExchangeUnit.value,
						invoice_address_id: invoiceAddressId.value,
						transaction_type: 'sales invoice ',
						// ****************************************************************
						is_fee_addable: isFeeAddable.value,
						paper_invoice_fee: invoiceFee.invoiceFeeAmount,
						paper_invoice_fee_vat: invoiceFee.invoiceFeeVat,
						paper_invoice_fee_vat_percent: 25,
					},
				],
				dispatch_number: dispatchNumber.value,
				invoice_number: invoiceNumber.value,
				our_company: ourCompany,
			}
		})
		//********************************************************************************
		const { mutate: addNewInvoice } = useMutation(
			CreateInvoiceHeadersMutation,
			() => ({
				variables: invoiceMutationVariable.value,
			}),
		)
		//********************************************************************************
		const onCancel = () => {
			showAddGoodsTransactions.value = false
			showCheckRiskModule.value = false
			showSearchActiveCustomers.value = true
		}
		const onClose = () => {
			isModalVisible.value = false
			isModalOrderVisible.value = false
			isModalDispatchVisible.value = false
			isModalInvoiceVisible.value = false
			// onCancel()
		}
		//*********************************************************************************
		const setOrderStatus = (type) => {
			return new Promise((resolve, reject) => {
				if (type !== null || undefined) {
					switch (type) {
						case 'save':
							isOrderLocked.value = false
							break
						case 'lock':
							isOrderLocked.value = true
							break
						case 'view':
							isOrderLocked.value = true
							break
						default:
							isOrderLocked.value = false
					}
					resolve('done')
				} else {
					const reason = new Error('Action type is not found')
					reject(reason)
				}
			})
		}
		// todo -----------------------------------------------------------------
		const {
			reportDocumentType,
			reportProcessType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			isUnlockButtonVisible,
			reportCustomer,
			reportCustomerEmail,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// todo -----------------------------------------------------------------
		const isModalOrderVisible = ref(false)
		const isModalDispatchVisible = ref(false)
		const isModalInvoiceVisible = ref(false)
		const condition2 = true
		const setVariables = () => {
			return new Promise((resolve, reject) => {
				if (condition2) {
					reportDocumentType.value = 'order'
					reportDocumentNumber.value = orderNumber.value
					reportDocumentOurCompany.value = ourCompany
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = false
					reportCustomer.value = `${customerId.value} - ${customerTitle.value} - ${customerNickname.value} - ${customerOrgNum.value}`
					reportDocumentDate.value = orderIssueDate
					reportDocumentStatus.value = `Order is locked and not dispatched`
					isUnlockButtonVisible.value = false
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo -----------------------------------------------------------------------------------
		const setVariablesPdfReport = (docType, docNum) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					reportDocumentType.value = docType
					reportDocumentNumber.value = docNum
					reportDocumentOurCompany.value = ourCompany
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = false
					reportCustomer.value = `${customerId.value} - ${customerTitle.value} - ${customerNickname.value} - ${customerOrgNum.value}`
					reportCustomerEmail.value = documentCustomerEmail.value
					reportDocumentDate.value = orderIssueDate
					reportDocumentStatus.value = 'locked'
					isUnlockButtonVisible.value = false
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ------------------------------------------------------------------------------------------------
		const getPdfReport = () => {
			setVariables().then(() => (isModalVisible.value = true))
		}
		// todo ---------------------------------------------------------------------------------------
		const onSave = (type) => {
			setOrderStatus(type)
			getNewOrderNumber()
				.then((result) => {
					const payload = ref(result.data.update_companies.returning[0])
					orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
				})
				.then(() => {
					console.log('orderMutationVariable', orderMutationVariable.value)
					addNewOrder()
				})
				.then(() => {
					if (type === 'save' || type === 'lock') {
						onCancel()
					} else {
						setAfterSaveState()
						getPdfReport()
					}
				})
		}
		// todo ---------------------------------------------------------------------------------------
		const cancelTheOrder = () => {
			const alertText = computed(() => {
				if (cancelOrderText.value === 'Close') {
					return `Are you sure to close the window!`
				} else {
					return `Are you sure to cancel the Order!`
				}
			})
			if (confirm(alertText.value)) {
				onCancel()
			}
		}
		// todo --------------------------------------------------------------------------------------
		const setAfterSaveState = () => {
			showSaveAllButton.value = false
			disableGetButtons.value = false
			isFormDisabled.value = true
			setCancelCloseText('Close')
			setCancelOrderText('Close')
		}
		const onSaveAndLock = () => {
			console.log(
				'******************** onSaveAndLock invoiceMutationVariable.value',
				invoiceMutationVariable.value,
			)
			if (createLevel.value === 1) {
				getNewOrderNumber()
					.then((result) => {
						const payload = ref(result.data.update_companies.returning[0])
						orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
					})
					.then(() => {
						addNewOrder().then(() => {
							setAfterSaveState()
						})
					})
			} else if (createLevel.value === 2) {
				setOrderStatus('lock')
				getNewOrderDispatchNumbers()
					.then((result) => {
						const payload = ref(result.data.update_companies.returning[0])
						orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
						dispatchNumber.value = `${payload.value.letter_code} ${payload.value.dispatch_number}`
					})
					.then(() => {
						addNewOrder()
							.then(() => addNewDispatch())
							.then(() => setCreditAmounts())
							.then(() => {
								setAfterSaveState()
							})
					})
			} else if (createLevel.value === 3) {
				console.log('getNewOrderDispatchInvoiceNumbers is fired')
				setOrderStatus('lock')
				getNewOrderDispatchInvoiceNumbers()
					.then((result) => {
						console.log('getNewOrderDispatchInvoiceNumbers', result)
						const payload = ref(result.data.update_companies.returning[0])
						orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
						dispatchNumber.value = `${payload.value.letter_code} ${payload.value.dispatch_number}`
						invoiceNumber.value = `${payload.value.letter_code} ${payload.value.invoice_number}`
					})
					.then(() => {
						addNewOrder()
							.then(() => addNewDispatch())
							.then(() => setCreditAmounts())
							.then((payload) => {
								if (payload === 'ok') {
									console.log('dispatchNumber', dispatchNumber.value)
									console.log('invoiceNumber', invoiceNumber.value)
									console.log('ourCompany', ourCompany.value)
									addNewInvoice()
								}
							})
							.then(() => {
								setAfterSaveState()
							})
							.catch((error) => {
								console.log('=======>>>>>> Error: ', error)
							})
					})
			} else {
				alert('Please select the Create Level')
			}
		}
		const onGetOrder = () => {
			setVariablesPdfReport('order', orderNumber.value).then(
				() => (isModalOrderVisible.value = true),
			)
		}
		// todo --------------------------------------------------------------------------------------
		const getDocNum = () => {
			return existingDispatchNumber.value.length === 9
				? existingDispatchNumber.value
				: dispatchNumber.value
		}
		const onGetDispatch = () => {
			const docNumber = getDocNum()
			setVariablesPdfReport('dispatch', docNumber).then(
				() => (isModalDispatchVisible.value = true),
			)
		}
		// todo --------------------------------------------------------------------------------------
		const onGetInvoice = () => {
			setVariablesPdfReport('sale-invoice', invoiceNumber.value).then(() => {
				isModalInvoiceVisible.value = true
			})
		}
		// todo --------------------------------------------------------------------------------------
		const onCancelAll = () => {
			onCancel()
		}
		// todo --------------------------------------------------------------------------------------
		const showGetExistingDispatch = ref(false)
		onUnmounted(() => {
			showGetExistingDispatch.value = false
			existingDispatchNumber.value = ''
			showDocumentHeadersGroup.value = false
		})
		// ***  --------------------------------------------------------------------------------
		const existingDispatchNumber = ref('')
		const addToDispatchMutationVariable = computed(() => {
			return {
				company_id: ourCompany,
				dispatch_number: existingDispatchNumber.value,
				order_number: orderNumber.value,
			}
		})
		const {
			mutate: AddToDispatch,
			onDone: onDoneAddToDispatch,
			onError: onErrorAddToDispatch,
		} = useMutation(AddToExistingDispatchMutation, () => ({
			variables: addToDispatchMutationVariable.value,
		}))

		// const addToExistingDispatch = () => {
		// 	if (existingDispatchNumber.value !== '') {
		// 		AddToDispatch()
		// 		showGetExistingDispatch.value = true
		// 	}
		// }

		const addToExistingDispatch = () => {
			setOrderStatus('lock')
			getNewOrderNumber()
				.then((result) => {
					const payload = ref(result.data.update_companies.returning[0])
					orderNumber.value = `${payload.value.letter_code} ${payload.value.order_number}`
					dispatchNumber.value = existingDispatchNumber.value
				})
				.then(() => {
					addNewOrder()
						.then(() => AddToDispatch())
						.then(() => {
							setAfterSaveState()
						})
				})
		}

		onDoneAddToDispatch(() => {
			console.log('onDone is fired')
			onGetDispatch()
		})

		onErrorAddToDispatch((Error) => {
			alert(`Could not add to existing dispatch: ${Error}`)
		})

		// *** --------------------------------------------------------------------------------
		return {
			boolTrue,
			boolFalse,
			disableRadioButton,
			numeral,
			applyInvoiceFee,
			feeMessage,
			// -----------------------
			createLevel,
			disableButtons,
			disableGetButtons,
			onSave,
			cancelTheOrder,
			isModalVisible,
			isModalOrderVisible,
			isModalDispatchVisible,
			isModalInvoiceVisible,
			showSaveAllButton,
			isFormDisabled,
			// ? -------------------------------------
			onSaveAndLock,
			onGetOrder,
			onGetDispatch,
			onGetInvoice,
			onCancelAll,
			// ? ---------------------------------------
			onClose,
			documentNumbers,
			cancelCloseText,
			cancelOrderText,
			// ****************************************
			existingDispatchNumber,
			addToExistingDispatch,
			showGetExistingDispatch,
		}
	},
}
</script>
